export default class Slider {
  constructor (obj = {}) {

    this.options = {
      activeClass: 'js--active',
    };
    this.$el = $('[data-slider]:not(.js--active)');
    this.$body = $('body');


    return this;
  }

  init () {


    this.$el.addClass(this.options.activeClass).each(function () {
      let $this = $(this);
      let data = $this.data('slider');

      $this.slick(data);
    });

  }


  render () {
    if (this.$el.length) this.init();
    return this;
  }
}
