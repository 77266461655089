import Slider from './modules/Slider';
import Action from './modules/Action';


$(() => new Slider().render());


// jQuery
(function ($) {
  $(function () {

    let $body = $('body');

    // Клик
    $body.on('click', '[data-click]', function () {
      let data = $(this).data('click');

      new Action(data, this);
      if ($(this).is('a')) return false;
    });


    $body.on('mousemove', function (event) {

      let y = event.originalEvent.pageY;
      let x = event.originalEvent.pageX;


      $('.js--mouse-watch').each(function (index, element) {
        let $this = $(this);
        let percent = parseInt($this.data('percent'));

        let offsetTop = $this.offset().top;
        let offsetLeft = $this.offset().left;

        let left = x - offsetLeft;
        left = left / percent;

        let top = y - offsetTop;
        top = top / percent;

        $this.css({'left': left + 'px', 'top': top + 'px'});

      });
    });


  });
})(jQuery);
